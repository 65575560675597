import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import { HttpClient } from '@angular/common/http'
import { apis } from 'src/environments/environment'
import moment from 'moment'
import { hasOwnProperty } from 'tslint/lib/utils'

@Component({
    selector: 'app-mentor',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.css']
})
export class InspectionComponent implements OnDestroy, OnInit {
    parallelName:any
    dataStatus = 'fetching'
    inspection15: Number = 1500
    inspection1: Number = 125
    packageAmount: Number = 0
    userBalance :any
    requiredBalance :any
    moment=moment
    bookingStatus :any
    editedPackageAmount: Number = this.packageAmount
    isEditing: boolean = false
    fetching: boolean = false
    isPack2Active: boolean = false
    baseUrl: any
    isPack1Active: boolean = false
    inspectionList: Array<any> = []
    selectedIndex: number
    modalRef: BsModalRef
    modalRefPayNow: BsModalRef
    modalRefRequest: BsModalRef
    pagination: Pagination<any>
    searchKeyword = ''
    payModal:any
    searchFromDate = ''
    mentorList: any[] = []
    searchEndDate = ''
    inprogress: boolean = false
    selectedStatus = ''
    searchKeyword$: Subject<string> = new Subject<string>()
    searchFromDate$: Subject<string> = new Subject<string>()
    searchEndDate$: Subject<string> = new Subject<string>()
    searchKeywordSub: any
    searchFromDateSub: any
    class1 = 'ASC arrow '
    class2 = 'DESC arrow active'
    searchEndDateSub: any
    requestDetail: any
    confirmPaymentDetails: any = {}
    paymentDetail: any = {}
    confirmPayment: boolean = false
    paymentFG: FormGroup
    pakistanCities = [
        'Karachi',
        'Lahore',
        'Islamabad',
        'Rawalpindi',
        'Faisalabad',
        'Multan',
        'Gujranwala',
        'Sialkot',
        'Peshawar',
        'Quetta',
        'Sukkur',
        'Hyderabad',
        'Gujrat',
        'Bahawalpur',
        'Sargodha',
        'Abbottabad',
        'Mardan',
        'Larkana',
        'Sahiwal',
        'Mirpur Khas',
        'Sheikhupura',
        'Jhang',
        'Rahim Yar Khan',
        'Okara'
    ]
    currentBooking :any
    maxBooking:any
    inspectionId:any
    modalTitle = ''
    loginLoading = false
    // dataForm: FormGroup
    selectedId: any
    d: any
    i: any
    filter: any = {
        startDate: '',
        endDate: '',
        page: 1
    }

    filters: any = {
        keyword: '',
        // startDate: moment(new Date()).format('YYYY-MM-DD'),
        // endDate: moment(new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
        city: '',
        mentorId: '',
        page: 1,
        per_page: 10
    }
    breadCrum = [
        {
            link: '/admin/mentor',
            value: 'Mentors'
        }
    ]
    loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(4, 7, 1)

    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }
    bookedBtn: boolean=false

    constructor(
        private ds: DataService,
        private fb: FormBuilder,
        public ui: UIHelpers,
        private ms: BsModalService,
        private alert: IAlertService,
        private http: HttpClient,
        public router: Router,
        private route: ActivatedRoute,
        public api: ApiService,
        public cs: ConstantsService
    ) {
        this.baseUrl = apis.baseUrl
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }
        this.ds.getMentor().subscribe((resp: any) => {
            this.mentorList = resp.data
        })
        this.parallelName = this.api.user.parallel.name
        const params = { ...this.filters }
        
        this.ds.getMyWallet(params).subscribe((resp) => {
            if (resp.success) {
                this.api.balance.next(resp.data.currentBalance)
                this.userBalance = resp.data.currentBalance
            } else {
                this.alert.error(resp.errors.general)
            }
        })

        this.ds.getPaymentDetail().subscribe((resp) => {
            
            if (resp.success) {
               this.api.singleDayInspectionAmount = resp.data.singleDayInspectionAmount
                this.requiredBalance = Number(resp.data.singleDayInspectionAmount)            }
                
                // this.api.balance.subscribe((r:any) => {
                //     this.userBalance = Number(r)
                // })

                console.log('user-balance',this.userBalance,'required---b',this.requiredBalance)
        
            })

       

       
        // this.dataForm = this.fb.group({
        //     name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        //     email: new FormControl(null, [Validators.required, Validators.email]),
        //     address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        //     contact: new FormControl(null, [Validators.required, Validators.max(999999999999999)])
        // })

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }
            if (params.perPage) {
                this.filters.per_page = params.perPage
            }
            if (params) {
                this.search()
            }
        })
    }

    ngOnInit(): void {
        //this.userBalance = Number(this.api.balance)
       // this.requiredBalance = Number(this.api.singleDayInspectionAmount)
        this.searchKeywordSub = this.searchKeyword$
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((searchKeyword) => {
                this.filters.page = 1
                this.search()
            })
        const param = { ...this.filter }
        this.paymentFG = new FormGroup({
            token: new FormControl('', Validators.required)
        })
    }
    forEachInspection() {
        this.isPack2Active = false
        this.isPack1Active = true
        this.packageAmount = 0
    }
    forManyInspection() {
        this.isPack1Active = false
        this.isPack2Active = true
        this.packageAmount = this.paymentDetail.fifteenDayInspectionAmount
    }
    clearAmount() {
        this.isPack1Active = false
        this.isPack2Active = false
        this.packageAmount = 0
        setTimeout(() => {
            this.confirmPayment = false
        }, 500)
    }
    enterEditMode() {
        this.editedPackageAmount = this.packageAmount
        this.isEditing = true
    }
    saveEditedAmount() {
        if (this.packageAmount !== this.inspection15) {
            this.packageAmount = this.editedPackageAmount
            this.isEditing = false
        }
        if (this.packageAmount === this.inspection15) {
            this.isPack2Active = true
            this.isPack1Active = false
            this.packageAmount = this.inspection15
        }
        if (this.packageAmount === 0) {
            this.isPack1Active = false
            this.isPack2Active = false
        }
    }
    setPagination(page: any) {
        this.filters.page = page
        this.router.navigate(['/parallel/inspection'], {
            queryParams: this.filters,
            replaceUrl: true
        })
    }

    searchKeywordChange(value: string) {
        this.searchKeyword$.next(value)
    }
    searchFromDateChange(value: any) {
        this.searchFromDate$.next(value)
    }
    searchEndDateChange(value: any) {
        this.searchFromDate$.next(value)
    }

    search(): void {
        let startDate=''
        let endDate=''
        if (this.filters.startDate != '' ) {
            startDate = this.filters.startDate
            
            this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD')
            
        }
        if (this.filters.endDate != '') {
           
            endDate = this.filters.endDate
            
            this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD')
        }
        this.waiting.search = true
        if (this.filters.mentorId === null) {
            this.filters.mentorId = ''
        }
        this.ds.getList(this.filters).subscribe((resp: any) => {
            this.waiting.search = false
            if (resp.success === true) {
                console.log(resp.data.data)
                this.inspectionList = resp.data.data || []
                this.pagination = resp.data as Pagination<any>
                
                console.log(this.bookedBtn,'bookedBtn');
                
                this.dataStatus = 'done'
            }
        })
       
            this.filters.startDate = startDate
            this.filters.endDate = endDate
        
        console.log(this.api.balance,'balance');
        console.log(this.api.singleDayInspectionAmount,'singleDayInspectionAmount');
        
        
    }

    onClear() {
        this.filters.keyword = ''
        this.filters.city = ''
        this.filters.mentorId = ''
        this.filters.startDate = ''
        this.filters.endDate = ''
        this.search()
    }

    checkBooking(id:any){
        let ind = this.inspectionList.findIndex((f => f.id == id))
        let cInsp = this.inspectionList[ind]

        if(cInsp?.currentBookings == cInsp?.maxBookings){
            return false
        }
       
        if(cInsp?.bookings.length > 0){
            let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
            if(cInd > -1){
                return false
            }
        }
        
        return true
    }

    checkBookingStatus(id:any){
        let ind = this.inspectionList.findIndex((f => f.id == id))
        let cInsp = this.inspectionList[ind]

        // if(cInsp?.currentBookings == cInsp?.maxBookings){
        //     return false
        // }

        if(cInsp?.bookings.length > 0){
            let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
            if(cInd > -1){
                if(cInsp[cInd].bookings.status == 'accepted'){
                    this.bookingStatus = 'Booked'
                   
                }

                if(cInsp[cInd].bookings.status == 'rejected'){
                    this.bookingStatus = 'Rejected'
                   
                }

                
            }
        }
        console.log('booking---status', this.bookingStatus)
      
    }

    checkMyBooking(id:any){
        let ind = this.inspectionList.findIndex((f => f.id == id))
        let cInsp = this.inspectionList[ind]
        if(cInsp?.bookings.length > 0){
            let cInd =    cInsp?.bookings.findIndex((p:any) => p.parallelId == this.api.user.id)
            if(cInd > -1){
                console.log('bookin---s',cInsp.bookings[cInd].status)
              this.bookingStatus = cInsp.bookings[cInd].status == 'accepted'?'Booked':'Rejected'
                return true
                
            }
        }
        return false
    }

    attendBookingConfirmation(attendBooking:any,id: any, maximumBooking: any, currentBooking: any, index: number,modal:any){
        this.selectedIndex = index
        this.inspectionId = id
        this.maxBooking = maximumBooking
        this.currentBooking = currentBooking

        this.modalRef = this.ms.show(attendBooking, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    bookingConfirmation(attendBooking:any){
        

        this.modalRef = this.ms.show(attendBooking, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }
    
    attendInspection() {
        this.loginLoading = true
        //this.selectedIndex = index
        let data = {
            inspectionId:this.inspectionId
        }

        if (this.currentBooking >= this.maxBooking) {
            this.alert.error('Maximum bookings limit reached')
            this.loginLoading = false
            return
        }
        this.ds.attendInspection(data).subscribe((resp: any) => {
            if (resp.success) {
                this.loginLoading = false
                this.modalRef.hide()
                this.inspectionList[this.selectedIndex] = resp.data
                this.alert.success('Booking created successfully')
                
                this.parallelName = this.api.user.parallel.name
                const params = { ...this.filters }
                this.ds.getMyWallet(params).subscribe((resp) => {
                    if (resp.success) {
                        // this.api.balance = resp.data.currentBalance
                        // this.userBalance = resp.data.currentBalance
                        this.api.balance.next(resp.data.currentBalance)
                    } 
                })
            } else {
                this.loginLoading = false
                
                this.alert.error('You cannot attend more than three ispections in a day')
            }
        })
    }

    // save(f: any) {
    //     this.loginLoading = true
    //     if (this.dataForm.invalid) {
    //         this.alert.error('Please fill in complete data & then try again')
    //         this.loginLoading = false
    //         return
    //     }
    //     if (this.dataForm.value.password !== this.dataForm.value.confirmPassword) {
    //         this.alert.error('Password does not match')
    //         this.loginLoading = false
    //         return
    //     }
    //     const params = {
    //         email: this.dataForm.get('email')?.value,
    //         contact: this.dataForm.get('contact')?.value,
    //         address: this.dataForm.get('address')?.value,
    //         name: this.dataForm.get('name')?.value
    //     }
    //     this.ds.add(params).subscribe((resp: any) => {
    //         this.loginLoading = false
    //         if (resp.status == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         } else {
    //             this.alert.success('Mentor add successfully!!')
    //         }
    //         f.resetForm()
    //         this.modalRef.hide()
    //         this.ds.getList().subscribe((resp: any) => {
    //             this.dataStatus = ''
    //             this.inspectionList = resp.data
    //             // console.log(this.inspectionList)
    //         })
    //     })

    //     // const catEmail = params.email
    //     // const catPassword = params.password
    //     // const catAddress = params.address
    //     // const catPhoneNumber = params.phoneNumber
    //     // let saveUpdate = this.ds.add({ email: catEmail, password: catPassword, address: catAddress, phoneNumber: catPhoneNumber})
    //     // if (this.selectedIndex !== -1) {
    //     //     saveUpdate = this.ds.update({ id: this.selectedId, title: catTitle, description: catDescription })
    //     // }

    //     //         saveUpdate.subscribe((resp: any) => {
    //     //             this.loginLoading = false
    //     //             if (resp.success === false) {
    //     //                 this.alert.error(resp.message)
    //     //                 this.loginLoading = false

    //     //             } else if (this.dataForm.value.id !== null) {
    //     //                 this.alert.success('Changes done successfully!!')
    //     //                 this.inspectionList[this.selectedIndex] = resp.data
    //     //                 this.dataForm.controls.id.setValue(null)

    //     //             } else {
    //     //                 params.id = resp.data.id
    //     //                 this.alert.success('Category added successfully!!')
    //     //                 // this.categoriesList.push(resp.data)
    //     //                 this.search()
    //     //             }
    //     //             f.resetForm()
    //     //             this.modalRef.hide()
    //     //             this.selectedId = -1

    //     //         })
    // }

    // get g() {
    //     return this.dataForm.controls
    // }

    // cancelButton(f: any) {
    //     f.resetForm()
    //     this.modalRef.hide()
    //     this.selectedIndex = -1
    // }

    cancel() {
        this.waiting.save = true
        const params = {
            inspectionId: this.selectedId,
            parallelId: this.api.user.id
        }
        this.ds.cancelBooking(params).subscribe((resp) => {
            this.waiting.save = false

            if (resp.success === false) {
                this.alert.error('Cancellation Failed ')
                this.modalRef.hide()
                return
            }

            //this.bookingsList.splice(this.selectedIndex, 1)
            this.modalRef.hide()
            this.alert.success('Booking cancelled Successfully!')
        })
    }

    // checkTime(inspectonDate: any, inspectionTime: any) {
    //     // Get the current time and add 15 minutes to it
    //     let currentTime = moment().add(15, 'minute');
    
    //     if (inspectionTime && inspectonDate != null) {
    //         // Combine inspection date and time into a single moment object
    //         let inspectionDateTime = moment(inspectonDate + ' ' + inspectionTime, 'YYYY-MM-DD HH:mm');
            
    //         // Log for debugging
    //         console.log('Current Time (+15 mins):', currentTime.format('YYYY-MM-DD HH:mm:ss'));
    //         console.log('Inspection DateTime:', inspectionDateTime.format('YYYY-MM-DD HH:mm:ss'));
    
    //         // Compare the current time with the inspection time
    //         if (currentTime.isBefore(inspectionDateTime)) {
    //             console.log('Current time is less than the inspection date/time.');
    //             return true;
    //         } else {
    //             console.log('Current time is greater than or equal to the inspection date/time.');
    //             return false;
    //         }
    //     }
    //     return false
    // }

    checkTime(inspectonDate: any, inspectionTime: any) {
        // Get the current time and add 15 minutes to it
        let currentTime = moment().add(15, 'minute');
    
        if (inspectionTime && inspectonDate != null) {
            // Parse and combine the inspection date and time into a single moment object
            let inspectionDateTime = moment(`${inspectonDate} ${inspectionTime}`, 'YYYY-MM-DD HH:mm:ss');
            
            // Log for debugging
            console.log('Current Time (+15 mins):', currentTime.format('YYYY-MM-DD HH:mm:ss'));
            console.log('Inspection DateTime:', inspectionDateTime.format('YYYY-MM-DD HH:mm:ss'));
    
            // Check if the combined inspection date and time is valid
            if (!inspectionDateTime.isValid()) {
                console.log('Invalid inspection date or time format.');
                return false;
            }
    
            // Compare the current time with the inspection time
            if (currentTime.isBefore(inspectionDateTime)) {
                console.log('Current time is less than the inspection date/time.');
                return true;
            } else {
                console.log('Current time is greater than or equal to the inspection date/time.');
                return false;
            }
        }
    
        return false;
    }
    
    

    checkBookingTime(item:any){
        
        // Combine the date and time from the item
        const bookingDateTime = moment(item.date + ' ' + item.time, 'YYYY-MM-DD HH:mm');

        // Get the current date and time
        const currentDateTime = moment();

        // Compare the booking date/time with the current date/time
        if (bookingDateTime < currentDateTime) {

            console.log('test time date is less ten now')
            // The booking date/time is in the future or equal to the current date/time
            return false;
        }

        // The booking date/time is in the past
        return true;

    }

    openModal(formModal: any, id: number, index: number) {
        this.modalTitle = 'Add New Inspection'
        //this.dataForm.controls.status.setValue('Active')
        this.selectedIndex = index
        if (index > -1) {
            this.modalTitle = 'Edit Mentor'
            this.selectedId = id
            this.selectedIndex = index
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-mg modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
    }

    statusConfirmingModal(changeStatus: TemplateRef<any>, id: number, ind: number, status: string) {
        this.selectedId = id
        this.selectedIndex = ind
        this.selectedStatus = status
        this.modalRef = this.ms.show(changeStatus, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    //     changeStatusActive() {
    //         this.waiting.userStatus[this.selectedIndex] = true
    //         const params = {
    //             id: this.selectedId,
    //             //status: this.selectedStatus
    //         }
    //         this.ds.activate(params).subscribe((resp: any) => {
    //             this.waiting.userStatus[this.selectedIndex] = false
    //             if (resp.success === true) {
    //                 this.alert.success('Status Changes Active Successfully')
    //                 this.modalRef.hide()
    //                 this.inspectionList[this.selectedIndex].status = this.selectedStatus
    //             } else {
    //                 this.alert.error(resp?.message || '')
    //             }
    //         })
    //     }

    // changeStatusInactive() {
    //     // this.waiting.userStatus[this.selectedIndex] = true
    //     // const params = {
    //     //     id: this.selectedId,
    //         //status: this.selectedStatus
    //     }
    //         this.ds.deactivate(params).subscribe((resp: any) => {
    //             this.waiting.userStatus[this.selectedIndex] = false
    //             if (resp.success === true) {
    //                 this.alert.success('Status Changes Inactive Successfully')
    //                 this.inspectionList[this.selectedIndex].status = this.selectedStatus
    //                 this.modalRef.hide()
    //             } else {
    //                 this.alert.error(resp?.message || '')
    //             }
    //         })
    //     }

    //     delete() {
    //         this.waiting.save = true
    //         const param = {
    //             id: this.selectedId
    //         }
    //         this.ds.delete(param).subscribe((resp: Resp<any>) => {
    //             this.waiting.save = false
    //             if (resp.success === false) {
    //                 this.alert.error(resp?.message as string)
    //                 this.modalRef.hide()
    //                 return
    //             }
    //             this.inspectionList.splice(this.selectedIndex, 1)
    //             this.modalRef.hide()
    //             this.alert.success('Category Deleted Successfully!')
    //         })
    //     }

    //     confirmDelModal(template: TemplateRef<any>, id: number, index: any) {
    //         this.selectedId = id
    //         this.selectedIndex = index
    //         this.modalRef = this.ms.show(template, {
    //             class: 'modal-sm modal-dialog-centered back-office-panel'
    //         })
    //     }

    // cancelRegionButton() {
    //     this.modalRef.hide()
    // }

    ngOnDestroy(): void {
        console.log('hello')

        // this.searchKeywordSub.unsubscribe()
    }
    // changeStatusInactive() {
    //     this.loginLoading = true
    //     const params = { id: this.selectedId }
    //     this.ds.activate(params).subscribe((resp: any) => {
    //         console.log(resp)
    //         this.loginLoading = false
    //         if (resp.success == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         } else {
    //             this.alert.success(resp.msg)
    //             this.inspectionList[this.selectedIndex].status = 'active'
    //             this.modalRef.hide()
    //         }
    //     })
    // }
    // changeStatusActive() {
    //     this.loginLoading = true
    //     const params = { id: this.selectedId }
    //     this.ds.deactivate(params).subscribe((resp: any) => {
    //         console.log(resp)
    //         this.loginLoading = false
    //         if (resp.success == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         } else {
    //             this.alert.success(resp.msg)
    //             this.inspectionList[this.selectedIndex].status = 'inactive'
    //             this.modalRef.hide()
    //         }
    //     })
    // }
    // delete() {
    //     this.waiting.save = true
    //     const params = { id: this.selectedId }
    //     this.ds.delete(params).subscribe((resp: any) => {
    //         this.waiting.save = false
    //         if (resp.success == false) {
    //             this.alert.error('Not Deleted')
    //             return
    //         }
    //         this.alert.success(resp.msg)
    //         this.inspectionList.splice(this.selectedIndex, 1)
    //         this.modalRef.hide()
    //     })
    // }

    openRequetDetailModal(formModal: any) {
        this.modalRef = this.ms.show(formModal, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    requestPackageDetail(packageDetail: any) {
        this.openRequetDetailModal(packageDetail)
    }

    requestDetails(orderDetail: any, data: any) {
        this.requestDetail = data
        // this.fromLat = Number(data.fromLat)
        // this.fromLng = Number(data.fromLng)
        // this.toLat = Number(data.toLat)
        // this.toLng = Number(data.toLng)
        this.openRequetDetailModal(orderDetail)
        console.log(this.requestDetail)
        // this.initMap()
    }

    // openRequestPayNowModal(template: any) {
    //     this.modalRefPayNow = this.ms.show(template, {
    //         class: 'modal-lg modal-dialog-centered back-office-panel',
    //         backdrop: 'static',
    //         ignoreBackdropClick: true,
    //         keyboard: false
    //     })
    // }


    openRequestPayNowModal(template: any) {
       this.modalRef.hide()
        this.fetching = true
        this.modalRefPayNow = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
        
        
        this.ds.getPaymentDetail().subscribe((resp) => {
            this.fetching = false
            if (resp.success) {
                if (resp.data != null) {
                    this.paymentDetail = resp.data
                    this.paymentDetail.perDayAmount =
                        this.paymentDetail.fifteenDayInspectionAmount / 15
                    if (
                        this.paymentDetail.perDayAmount <
                        this.paymentDetail.singleDayInspectionAmount
                    ) {
                        this.paymentDetail.savingPerDay =
                            this.paymentDetail.singleDayInspectionAmount -
                            this.paymentDetail.perDayAmount
                    }
                } else {
                }
            } else {
            }
        })

       
    }

    openRequestModal(template: any) {
        this.modalRefRequest = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    closeModals1() {
        if (this.modalRefPayNow) {
            this.modalRefPayNow.hide()
        }
    }

    closeModals2() {
        if (this.modalRefRequest) {
            this.modalRefRequest.hide()
        }
    }
    payRequest() {
        this.waiting.save = true
        let params = {
            amount: this.packageAmount,
            package: true
        }
        if (this.packageAmount !== this.paymentDetail.fifteenDayInspectionAmount) {
            params.package = false
        }
        this.ds.payRequest(params).subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success) {
                this.confirmPaymentDetails = resp.data
                this.confirmPaymentDetails.url = atob(resp.data.url)
                this.paymentFG.controls.token.setValue(resp.data.token)
                this.confirmPayment = true
            } else {
                this.alert.error(resp.errors.general)
            }
        })
    }

    doSortDate(order: any) {
        // this.filters.orderBy = orderBy

        if(order == 'ASC'){
            this.class1 = 'ASC arrow active'
            this.class2 = 'DESC arrow'
        }

        if(order == 'DESC'){
            this.class1 = 'ASC arrow'
            this.class2 = 'DESC arrow active'
        }
        this.filters.order = order
        this.search()
    }


    submitForm() {
        const form: any = window.document.getElementById('formAuthorizeNetTestPage')
        form.submit()
    }
    showBookedBtn(bookings:any) {
        console.log('prallelid',this.api.user.id);
        
        return bookings.some((booking: { parallelId: number }) => booking.parallelId=== this.api.user.id);  
    }
}
