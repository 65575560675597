import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { apis } from 'src/environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/parallel`
    private data = new BehaviorSubject<Array<any>>([{ fetching: true }])
    data$ = this.data.asObservable()

    constructor(public http: HttpClient) { }

    getList(params: any): Observable<any> {
        const url = `${this.baseUrl}/inspections`
        return this.http.get<any>(url, { params })
    }

    getMentor(): Observable<any> {
        const url = `${this.baseUrl}/dropdown/mentors`
        return this.http.get<any>(url)
    }

    getMyWallet(params: any): Observable<any> {
        const url = `${this.baseUrl}/my-wallet`
        return this.http.get<any>(url, { params })
    }

    cancelBooking(params: any): Observable<any> {
        const url = `${this.baseUrl}/inspections/remove-booking`
        return this.http.post<any>(url, params)
    }

    getPaymentDetail(): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/detail`
        return this.http.get<any>(url)
    }

    attendInspection(params: any): Observable<any> {
        const url = `${this.baseUrl}/inspections/attend`
        return this.http.post<any>(url, params)
    }

    // deactivate(params: any): Observable<any> {
    //     const url = `${this.baseUrl}/mentors/deactivate`
    //     console.log(params)
    //     return this.http.post<any>(url, params)
    // }

    // delete(params: any): Observable<any> {
    //     const url = `${this.baseUrl}/mentors/delete`
    //     return this.http.post<any>(url, params)
    // }

    // add(params: any): Observable<any> {
    //     const url = `${this.baseUrl}/mentors/add`
    //     return this.http.post<any>(url, params)
    // }

    // update(params: any): Observable<any> {
    //     const url = `${this.baseUrl}/mentors/update`

    //     return this.http.post<any>(url, params)
    // }
    payRequest(params: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/my-wallet/pay`, params)
    }
    afterPayresquest(params: any): Observable<any> {
        return this.http.post(`https://test.authorize.net/payment/payment`, params)
    }
}
