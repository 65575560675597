<h1 class="title">Inspection List</h1>
<!-- <app-breadcrums [data]="breadCrum"></app-breadcrums> -->
<div class="row">
    <div class="col-md-2 cg-input-group">
        <label>Address</label>
        <div class="group">
            <input type="text" class="cg" [(ngModel)]="filters.keyword" placeholder="Enter Address" (ngModelChange)="searchKeywordChange($event); filters.page = 1" />
        </div>
    </div>
    <div class="col-md-3 cg-input-group">
        <label>Date</label>
        <div style="display: flex; gap: 0.5rem">
            <input type="text"  [(ngModel)]="filters.startDate" placeholder="From"  class="cg form-control" bsDatepicker
            [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
            />
            <input type="text" [(ngModel)]="filters.endDate" placeholder="To" [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" class="cg form-control" bsDatepicker />
        </div>
        <!-- <select class="cg" [(ngModel)]="filters.status" (change)="filters.page = 1; search()">
            <option value="">Select Any</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
        </select> -->
    </div>
    <div class="col-md-2 cg-input-group">
        <label>City</label>
        <input type="text" class="cg" [(ngModel)]="filters.city" placeholder="Enter City" />
        <!-- <select class="cg" [(ngModel)]="filters.city" (change)="filters.page = 1; search()">
            <option value="">Select City</option>
            <option value="{{ item }}" *ngFor="let item of pakistanCities">{{ item }}</option>
        </select> -->
    </div>
    <div class="col-md-2 cg-input-group">
        <label>Mentor</label>
        <select class="cg" [(ngModel)]="filters.mentorId" (change)="filters.page = 1; search()">
            <option value="">Select Mentor</option>
            <option value="{{ item.id }}" *ngFor="let item of mentorList">{{ item.name }}</option>
        </select>
    </div>
    <div class="col cg-input-group d-flex justify-content-between align-items-end">
        <!-- <div class="btn-group" dropdown>
            <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button"
                class="cg secondary m-1" (click)="filters.page = 1; search()">
                Search
                <span></span>
                <span></span>
                <span></span>
            </button>
            <button class="cg secondary m-1" (click)="onClear()">
                Clear
                <span></span>
                <span></span>
                <span></span>
            </button>
            <button class="cg success m-1" style="width: fit-content"
                (click)="openRequestPayNowModal(requestPayNowTemplate)">Recharge Balance</button>
        </div> -->

        <div class="buttons-container d-flex justify-content-center align-items-center">
            <div class="cg-button-group">
                <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button" class="cg post secondary" (click)="filters.page = 1; search()">
                    Search
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <label>
                    <div class="icon secondary post">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <input type="checkbox" />
                    <div class="button-menu secondary">
                        <ul>
                            <li class="text-center" (click)="openRequestPayNowModal(requestPayNowTemplate)">Recharge Balance</li>
                            <li class="text-center" (click)="onClear()">Clear</li>
                        </ul>
                    </div>
                </label>
            </div>
        </div>
        <!-- <button type="button" class="cg primary" (click)="openModal(addForm, -1, -1)">Add New</button> -->
    </div>
</div>
<div class="box">
    <div class="table-responsive">
        <table class="cg primary">
            <thead class="sticky">
                <tr>
                    <th style="width: 17%">Complete Address</th>
                    <th style="width: 25%">Description</th>
                    
                    
                    <th style="width: 10%" class="text-center">
                        Date
                        <span class="arrows">
                            <span (click)="doSortDate('ASC')" [class]="class1"></span>
                            <span (click)="doSortDate('DESC')" [class]="class2"></span>
                        </span>
                    
                    </th>
                    
                    
                    
                    <th style="width: 10%" class="text-center" style="width: 10%">Time</th>
                    <th style="width: 10%" class="text-center">Status</th>
                    <th style="width: 10%" class="text-center">MaxBookings</th>
                    <th style="width: 10%" class="text-center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>
            <tbody>
                <tr *ngFor="let item of inspectionList; let i = index">
                    <td>
                       <p> {{ item.address }}</p> 
                        <p>{{item.addressTwo}}</p> 
                       <p>{{item.city}} ,{{item.zipCode}}</p> 
                        <div style="display: flex; gap: 0.5rem">
                            <b>By</b>
                            <p>{{ item.mentor.name }}</p>
                        </div>
                    </td>
                    <td>
                        <app-read-more *ngIf="item.description !== null" [text]="item.description" [maxLength]="50"></app-read-more>
                        <p *ngIf="item.description === null">--</p>
                    </td>
                    <!-- <td *ngIf="item.description == null || item.description === '' || item.description == undefined">-</td> -->
                    <td class="text-center">
                        {{ moment(item.date).format('MM-DD-YY') }}
                    </td>
                    <td class="text-center">
                        {{moment(item.startTime, 'HH:mm').format('hh:mm A')}}<br />
                    </td>
                    <td class="text-center">
                        <div class="cg-badge lg {{ item.status }}  text-center" style="font-size: 13px">{{ item.status }}</div>
                    </td>
                    <!-- <td class="text-center" *ngIf="item.currentBookings < item.maxBookings">{{ item.currentBookings }} / {{ item.maxBookings }}</td>
                    <td class="text-center" style="font-weight: 600; color: red" *ngIf="item.currentBookings === item.maxBookings">{{ item.currentBookings }} / {{ item.maxBookings }}</td> -->
                    <td class="text-center">
                        <div *ngIf="item.currentBookings === item.maxBookings" class="danger cg-badge text-center" style="font-size: 13px">{{ item.currentBookings }} / {{ item.maxBookings }}</div>
                        <div *ngIf="item.currentBookings !== item.maxBookings" class="text-center" style="font-size: 13px">{{ item.currentBookings }} / {{ item.maxBookings }}</div>
                    </td>
                    <td>
                        <div style="display: flex; gap: 5px">

                            <!-- <button class="cg info" (click)="api.balance < api.singleDayInspectionAmount ? openRequestPayNowModal(requestPayNowTemplate) : attendInspection(item.id, item.maxBookings, item.currentBookings, i)" *ngIf="checkBooking(item.id)">Attend</button> -->
                            
                            
                            
                            
                            <ng-container *ngIf="checkTime(item.date,item.startTime)">
                                <ng-container *ngIf="userBalance < requiredBalance">
                                    <!-- <button class="cg info" (click)="openRequestPayNowModal(requestPayNowTemplate)" *ngIf="item.bookings.length == 0">Attend</button> -->
                                    <button class="cg info" (click)="bookingConfirmation(InspectionConfirmation)" *ngIf="item.bookings.length == 0">Attend</button> 
                                
                                </ng-container>
                            
                                <ng-container *ngIf="userBalance >= requiredBalance">
                                    <button class="cg info" (click)="attendBookingConfirmation(attendInspectionConfirmation,item.id, item.maxBookings, item.currentBookings, i)" *ngIf="item.bookings.length == 0">Attend</button> 
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="checkMyBooking(item.id)">
                                <button class ="{{this.bookingStatus == 'Booked'? 'cg success':'cg danger'}}" >{{this.bookingStatus}}</button>
                                <!-- <button class="cg" style="background: green" *ngIf="item?.bookings[0]?.status === 'accepted'">Booked</button> -->
                                <ng-container *ngIf="checkBookingTime(item) && this.bookingStatus == 'Booked'">
                                    <button class ="cg danger" (click)="openModal(confirmDel,item.id,i)" >Cancel</button>
                                </ng-container>
                            </ng-container>

                            <button class="cg primary" (click)="requestDetails(requestDetailTemplete, item)">Detail</button>
                        </div>
                    </td>

                    <!-- <td class="d-flex justify-content-center align-items-center" style="gap: 4px">
                        <button style="width: 65px" type="button" (click)="openModal(ChangeStatusActive, item.id, i)" *ngIf="item.status == 'active'" class="sm cg btn-mr warning">Deactivate <span></span><span></span><span></span></button>
                        <button style="width: 65px" type="button" (click)="openModal(ChangeStatusInactive, item.id, i)" class="sm cg btn-mr success" *ngIf="item.status == 'inactive'">Activate <span></span><span></span><span></span></button>
                        <button class="cg sm danger" type="button" (click)="openModal(confirmDel, item.id, i)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td> -->
                </tr>
            </tbody>
            <tbody *ngIf="inspectionList?.length === 0 && dataStatus !== 'fetching'">
                <tr>
                    <td colspan="7">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)" *ngIf="dataStatus === 'done'"></app-ipagination>

<ng-template #requestPayNowTemplate>
    <ng-container> </ng-container>
    <div class="modal-header" [ngStyle]="{ padding: waiting.save ? '0' : '50px' }" style="background: rgba(221, 221, 221, 1)" *ngIf="waiting.save || fetching">
        <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </div>
    <div class="modal-body" style="background: rgba(221, 221, 221, 1)" *ngIf="!fetching">
        <div class="container-package" *ngIf="!confirmPayment">
            <div class="packages">
                <span class="borders"></span>
                <div class="pack1" [ngClass]="{ active: isPack2Active }" (click)="forManyInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text">
                        <div>
                            <h2>15 Inspections In</h2>
                            <h1>${{ paymentDetail.fifteenDayInspectionAmount | number: '1.2-2' }}</h1>
                        </div>
                        <div>
                            <h3>Per inspection ${{ paymentDetail.perDayAmount | number: '1.2-2' }}</h3>
                            <h3 *ngIf="paymentDetail.savingPerDay">${{ paymentDetail.savingPerDay | number: '1.2-2' }} per inspection saving</h3>
                        </div>
                    </div>
                </div>
                <div class="pack2" [ngClass]="{ active: isPack1Active }" (click)="forEachInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text" style="padding: 10px 20px; text-align: center">
                        <h2>Add balance to your wallet</h2>
                        <h3>* Pay for each inspection at ${{ paymentDetail.singleDayInspectionAmount }}, 15th is free</h3>
                        <!-- <div>
                            <h2>Pay for each inspection at</h2>
                            <h1>{{ inspection1 }}$</h1>
                        </div>
                        <div>
                            <h3>15th is free</h3>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="amount" *ngIf="isPack1Active || isPack2Active">
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="isPack1Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <input style="border: 0; outline: 0; width: 80px; font-size: 24px" type="number" [(ngModel)]="packageAmount" />
                </div>
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="packageAmount == paymentDetail.fifteenDayInspectionAmount && isPack2Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <h1>${{ packageAmount }}</h1>
                </div>
                <button [disabled]="packageAmount != 0 && waiting.save" class="pac-pay" type="button" (click)="payRequest()" *ngIf="packageAmount !== 0 && packageAmount != null">Pay Now</button>
            </div>
            <div class="amount" *ngIf="packageAmount <= 0 && !isPack1Active">
                <h2>Please Select Option</h2>
            </div>
        </div>

        <div class="payment-confirmation" *ngIf="confirmPayment">
            <p class="heading">Are you sure to pay ${{ packageAmount }}?</p>
            <form [formGroup]="paymentFG" method="post" [action]="this.confirmPaymentDetails.url" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" (ngSubmit)="submitForm()">
                <input type="hidden" name="token" formControlName="token" />
                Continue to Authorize.net to Payment Page
                <button type="submit" class="cg success" style="margin-left: 20px">Continue to next page</button>
            </form>
        </div>
        <div class="modal-footer" style="background: rgba(221, 221, 221, 1)">
            <button type="button" class="cg primary ms-2" (click)="closeModals1(); clearAmount()">Cancel</button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #requestPayNowTemplate>
    <ng-container *ngIf="dataStatus === 'fetching'">
        <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </ng-container>
    <div class="modal-header" style="background: rgba(221, 221, 221, 1)"></div>
    <div class="modal-body" style="background: rgba(221, 221, 221, 1)">
        <div class="container-package" *ngIf="!confirmPayment">
            <div class="packages">
                <span class="borders"></span>
                <div class="pack1" [ngClass]="{ active: isPack2Active }" (click)="forManyInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text">
                        <div>
                            <h2>15 Inspections In</h2>
                            <h1>{{ inspection15 }}$</h1>
                        </div>
                        <div>
                            <h3>Per inspection 100$</h3>
                            <h3>25$ per inspection saving</h3>
                        </div>
                    </div>
                </div>
                <div class="pack2" [ngClass]="{ active: isPack1Active }" (click)="forEachInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text" style="padding: 10px 20px; text-align: center">
                        <h2>Add balance to your wallet</h2>
                        <h3>* Pay for each inspection at 125$, 15th is free</h3>

                    </div>
                </div>
            </div>
            <div class="amount" *ngIf="isPack1Active || isPack2Active">
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="isPack1Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <input style="border: 0; outline: 0; width: 80px; font-size: 24px" type="number" [(ngModel)]="packageAmount" />
                </div>
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="packageAmount === 1500">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <h1>{{ packageAmount }}$</h1>
                </div>
                <button [disabled]="packageAmount === 0" class="pac-pay" type="button" (click)="payRequest()" *ngIf="packageAmount !== 0 && packageAmount != null">Pay Now</button>
            </div>
            <div class="amount" *ngIf="packageAmount <= 0 && !isPack1Active">
                <h2>Please Select Option</h2>
            </div>
        </div>

        <div class="payment-confirmation" *ngIf="confirmPayment">
            <p class="heading">Are you sure to pay ${{ packageAmount }}?</p>
            <form [formGroup]="paymentFG" method="post" [action]="this.confirmPaymentDetails.url" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" (ngSubmit)="submitForm()">
                <input type="hidden" name="token" formControlName="token" />
                Continue to Authorize.net to Payment Page
                <button type="submit" class="cg success" style="margin-left: 20px">Continue to next page</button>
            </form>
        </div>
    </div>
    <div class="modal-footer" style="background: rgba(221, 221, 221, 1)">
        <button type="button" class="cg primary ms-2" (click)="closeModals1(); clearAmount()">Cancel</button>
    </div>
</ng-template> -->

<ng-template #requestDetailTemplete>
    <div class="modal-header">
        <h1 class="modal-title pull-left">Detail</h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <!-- <div class="col-md-4">
                <h3>Address:</h3>
                {{ requestDetail.address }}
            </div> -->

            <div class="col-md-4">
                <h3>Date:</h3>
                {{ requestDetail.date }}
            </div>

            <div class="col-md-4">
                <h3>Time:</h3>
                
                {{moment(requestDetail.startTime, 'HH:mm').format('hh:mm A')}}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>Description:</h3>
                {{ requestDetail.description }}
            </div>
        </div>

        <div class="row mt-6">
            <div class="col-md-4">
                <h3>Complete Address:</h3>
                <p>{{ requestDetail.address }} </p>
                <p>{{requestDetail.addressTwo}} </p>
                {{requestDetail.city}} ,{{requestDetail.zipCode}}
            </div>

            <!-- <div class="col-md-4">
                <h3>State:</h3>
                {{ requestDetail.state }}
            </div> -->

            <div class="col-md-4">
                <h3>Status:</h3>
                {{ requestDetail.status }}
            </div>
        </div>

        <div class="row mt-4">
            <!-- <div class="col-md-4">
                <h3>Zip-Code:</h3>
                {{ requestDetail.zipCode }}
            </div> -->

            <div class="col-md-4">
                <h3>MaxBookings:</h3>
                {{ requestDetail.maxBookings }}
            </div>

            <div class="col-md-4">
                <h3>Mentor Name:</h3>
                {{ requestDetail.mentor.name }}
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="cg info" (click)="attendInspection(requestDetail.id, requestDetail.maxBookings, requestDetail.currentBookings)" *ngIf="checkBooking(requestDetail.id)">Attend</button>
        <ng-container *ngIf="requestDetail?.bookings?.length > 0">
            <button class="cg danger" *ngIf="requestDetail.bookings.length > 0 && requestDetail?.bookings[0]?.status === 'rejected'">Rejected</button>
            <button class="cg" style="background: green" *ngIf="requestDetail.bookings.length > 0 && requestDetail.bookings[0]?.status === 'accepted'">Booked</button>
        </ng-container>
        
        <button type="button" class="cg primary ms-2" (click)="modalRef.hide()">Close</button>
    </div>
</ng-template>

<!-- <ng-template #confirmDel>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Do you want to Delete?</p>
        <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg primary" (click)="delete()">
            Yes
            <span></span>
            <span></span>
            <span></span>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template> -->
<!-- <button (click)="test()">Test</button>  -->
<!-- <ng-template #addForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">{{ modalTitle }}</h2>
        <form [formGroup]="dataForm" (ngSubmit)="save(f)" #f="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Name <span class="mendatory">*</span></label>
                        <input formControlName="name" class="cg mt-0" placeholder="Enter Name" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.name, f)" class="errors">
                        <p *ngIf="g.name.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Email <span class="mendatory">*</span></label>
                        <input formControlName="email" class="cg mt-0" placeholder="Enter Email" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.email, f)" class="errors">
                        <p *ngIf="g.email.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory">*</span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Phone Number<span class="mendatory">*</span></label>
                        <input formControlName="contact" class="cg mt-0" placeholder="Enter Phone Number" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.contact, f)" class="errors">
                        <p *ngIf="g.contact.errors?.required">Title is required</p>
                    </div>
                </div>
            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="loginLoading" [ngClass]="loginLoading ? 'in-progress' : ''">Save <span></span><span></span><span></span></button>
            <button type="button" class="cg danger" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template>

<ng-template #ChangeStatusActive>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Are you sure that you want change the status to Active?</p>
        <button type="button" class="cg primary" (click)="changeStatusActive()">
            <div class="loading-dots" [ngClass]="loginLoading ? 'in-progress' : ''">Yes <span></span><span></span><span></span></div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>
-->
<ng-template #attendInspectionConfirmation>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Performing this action will result in your account being charged. Continue?</p>
        <button type="button" class="cg primary" (click)="attendInspection()">
            <div class="loading-dots" [ngClass]="loginLoading ? 'in-progress' : ''">Yes <span></span><span></span><span></span></div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #InspectionConfirmation>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>This will remove funds from your account. Do you wish to continue?</p>
        <button type="button" class="cg primary" (click)="openRequestPayNowModal(requestPayNowTemplate)">
            <div class="loading-dots" [ngClass]="loginLoading ? 'in-progress' : ''">Yes <span></span><span></span><span></span></div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #confirmDel>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Clicking Yes will cancel this booking and cannot be undone</p>
        <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg primary" (click)="cancel()">
            Yes
            <span></span>
            <span></span>
            <span></span>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>
